<script setup lang="ts">
import ShiftMemberActivityFeed from '@app/components/activityFeeds/shifts/ShiftMemberActivityFeed.vue'
import Badge from '@app/components/ui/badge/Badge.vue'
import DetailCard from '@app/components/ui/card/DetailCard.vue'
import DetailCardLabel from '@app/components/ui/card/DetailCardLabel.vue'
import DetailCardTab from '@app/components/ui/card/DetailCardTab.vue'
import DetailCardTitle from '@app/components/ui/card/DetailCardTitle.vue'
import DetailItem from '@app/components/ui/details/DetailItem.vue'
import { useColor } from '@app/composables/useColor'
import { useLocale } from '@app/composables/useLocale'
import type { ShiftMember } from '@app/types/shifts'
import { getDateFromDateTime, getTimeFromDateTime } from '@app/utils/date'
import { ExclamationCircleIcon } from '@heroicons/vue/16/solid'
import { Bars4Icon, DocumentTextIcon } from '@heroicons/vue/20/solid'
import ApproveShiftMember from '../members/actions/ApproveShiftMember.vue'
import DeclineShiftMember from '../members/actions/DeclineShiftMember.vue'
import ShiftMemberIssues from '../members/tabs/ShiftMemberIssues.vue'

const { shiftMember, defaultTab = 'details' } = defineProps<{
    shiftMember: ShiftMember
    defaultTab?: string
}>()

const emit = defineEmits(['close'])

const { dbTranslate } = useLocale()
const { getShiftMemberStatusColor } = useColor()
</script>

<template>
    <DetailCard :model="shiftMember" :dismissable="true" @close="emit('close')" :activeTab="defaultTab">
        <div class="flex w-full flex-col items-center justify-between gap-4">
            <DetailCardTitle>{{ shiftMember.user.full_name }}</DetailCardTitle>
            <div class="flex flex-col items-start gap-4">
                <DetailCardLabel :label="$t('attributes.status') + ' :'">
                    <div>
                        <Badge
                            size="lg"
                            class="leading-6"
                            :label="dbTranslate(shiftMember.status.name)"
                            :color="getShiftMemberStatusColor(shiftMember.status.slug)"
                        />
                    </div>
                </DetailCardLabel>
                <DetailCardLabel :label="$t('attributes.client_project') + ' :'">
                    <span class="text-sm leading-6 font-semibold uppercase"> {{ shiftMember.client_project.name }} </span>
                </DetailCardLabel>
                <DetailCardLabel v-if="shiftMember.branch" :label="$t('attributes.branch') + ' :'">
                    <span class="text-sm leading-6 font-semibold uppercase"> {{ shiftMember.branch.name }} </span>
                </DetailCardLabel>
            </div>
        </div>
        <template #actions>
            <ApproveShiftMember :shift-member />
            <DeclineShiftMember :shift-member />
        </template>
        <template #tabs>
            <DetailCardTab tab="details" label="Details">
                <template #icon>
                    <DocumentTextIcon class="size-5" />
                </template>
                <div class="mt-4 grid grid-cols-2 gap-4">
                    <DetailItem :label="$t('attributes.client_project')">
                        {{ shiftMember.client_project.name }}
                    </DetailItem>
                    <DetailItem v-if="shiftMember.branch" :label="$t('attributes.branch_id')">
                        {{ shiftMember.branch.name }}
                    </DetailItem>
                    <DetailItem :label="$t('attributes.location_type')">
                        {{ shiftMember.location_type.name }}
                    </DetailItem>
                    <DetailItem :label="$t('attributes.date')">
                        {{ getDateFromDateTime(shiftMember.shift_start) }}
                    </DetailItem>
                    <DetailItem :label="$t('shifts.start_time')">
                        {{ getTimeFromDateTime(shiftMember.shift_start) }}
                    </DetailItem>
                    <DetailItem :label="$t('shifts.end_time')">
                        {{ getTimeFromDateTime(shiftMember.shift_end) }}
                    </DetailItem>
                    <DetailItem v-if="shiftMember.start" :label="$t('shifts.actual_start_time')">
                        {{ getTimeFromDateTime(shiftMember.start) }}
                    </DetailItem>
                    <DetailItem v-if="shiftMember.end" :label="$t('shifts.actual_end_time')">
                        {{ getTimeFromDateTime(shiftMember.end) }}
                    </DetailItem>
                    <DetailItem v-if="shiftMember.shift_break_duration_minutes" :label="$t('shifts.obliged_break_duration')">
                        {{ shiftMember.shift_break_duration_minutes }}
                    </DetailItem>
                    <DetailItem v-if="shiftMember.shift_break_max_extra_minutes" :label="$t('shifts.optional_break_duration')">
                        {{ shiftMember.shift_break_max_extra_minutes }}
                    </DetailItem>
                    <DetailItem v-if="shiftMember.break_duration_minutes !== null" :label="$t('shifts.actual_break_duration')">
                        {{ shiftMember.break_duration_minutes }}
                    </DetailItem>
                    <DetailItem v-if="shiftMember.travel_time_minutes !== null" :label="$t('shifts.travel_time_minutes')">
                        {{ shiftMember.travel_time_minutes }}
                    </DetailItem>
                    <DetailItem v-if="shiftMember.hourly_wage" :label="$t('shifts.hourly_wage')">
                        {{ shiftMember.hourly_wage.formatted }}
                    </DetailItem>
                    <DetailItem :label="$t('attributes.age')">
                        {{ shiftMember.age }}
                    </DetailItem>
                    <DetailItem :label="$t('attributes.work_type_rank')">
                        {{ shiftMember.work_type_rank }}
                    </DetailItem>
                    <DetailItem v-if="shiftMember.base_wage" :label="$t('shifts.base_wage')">
                        {{ shiftMember.base_wage.formatted }}
                    </DetailItem>
                </div>
            </DetailCardTab>
            <DetailCardTab v-if="shiftMember.issues.length" tab="issues" label="Issues">
                <template #icon>
                    <ExclamationCircleIcon class="size-5" />
                </template>
                <ShiftMemberIssues :shift-member />
            </DetailCardTab>
            <DetailCardTab tab="activity" :label="$t('activities.label')">
                <template #icon>
                    <Bars4Icon />
                </template>
                <ShiftMemberActivityFeed :shift-member />
            </DetailCardTab>
        </template>
    </DetailCard>
</template>

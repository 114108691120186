<script setup>
import { Bars3BottomLeftIcon } from '@heroicons/vue/24/outline'
import { inject } from 'vue'

const mobileMenuOpen = inject('mobileMenuOpen')
</script>

<template>
    <button
        type="button"
        class="bg-fonky-black focus:ring-primary-600 px-4 text-white focus:ring-2 focus:outline-hidden focus:ring-inset md:hidden md:bg-white"
        @click="mobileMenuOpen = true"
    >
        <span class="sr-only">Open sidebar</span>
        <Bars3BottomLeftIcon class="h-6 w-6" aria-hidden="true" />
    </button>
</template>

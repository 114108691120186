<script setup lang="ts">
import ContentTabs from '@app/components/layout/ContentTabs.vue'
import TwoPaneLayout from '@app/components/layout/TwoPaneLayout.vue'
import ShiftMemberCard from '@app/components/shifts/shifts/ShiftMemberCard.vue'
import FromToDate from '@app/components/ui/table/column/FromToDate.vue'
import DataTable from '@app/components/ui/table/DataTable.vue'
import DropdownMultiFilter from '@app/components/ui/table/filters/DropdownMultiFilter.vue'
import ResetFilterButton from '@app/components/ui/table/filters/ResetFilterButton.vue'
import FilterSection from '@app/components/ui/table/FilterSection.vue'
import { useNavigation } from '@app/composables/layout/useNavigation'
import { useDetailCard } from '@app/composables/useDetailCard'
import { useLocale } from '@app/composables/useLocale'
import type { Branch, ClientProject, LocationType, ResourceCollection } from '@app/types/shared'
import type { ShiftMember } from '@app/types/shifts'
import { computed, toRefs } from 'vue'
import { route } from 'ziggy-js'

const props = defineProps<{
    shiftMembers: ResourceCollection<ShiftMember>
    selectedShiftMember: ShiftMember | null
    branches: Branch[]
    clientProjects: ClientProject[]
    locationTypes: LocationType[]
}>()

const { selectedShiftMember } = toRefs(props)

const { shiftQualityControlTabs } = useNavigation()
const { toggleCard } = useDetailCard(selectedShiftMember, 'selectedShiftMember')

const { dbTranslate } = useLocale()

const columns = computed(() => [
    {
        name: 'name',
        header: 'Name',
    },
    {
        name: 'date',
        header: 'attributes.date',
    },
    {
        name: 'status',
        header: 'attributes.status',
    },
    {
        name: 'branch',
        header: 'attributes.branch_id',
    },
    {
        name: 'clientProject',
        header: 'attributes.client_project',
    },
    {
        name: 'locationType',
        header: 'attributes.location_type',
        show: route().params.workType !== 'contact-center',
    },
])
</script>

<template>
    <ContentTabs :items="shiftQualityControlTabs" />
    <TwoPaneLayout class="scrollbar-hide mt-2">
        <DataTable
            class="flex flex-col rounded-lg border border-gray-100 transition-all duration-500"
            :items="shiftMembers"
            :columns="columns"
            @row:clicked="(shiftMember: ShiftMember) => toggleCard(shiftMember.id)"
            :activeId="selectedShiftMember?.id"
            only="shiftMembers"
            :clickable="true"
        >
            <template #filter>
                <FilterSection enable-search>
                    <DropdownMultiFilter border :items="clientProjects" :label="$t('attributes.client_project')" slug="client_project" />
                    <DropdownMultiFilter
                        v-if="route().params.workType !== 'contact-center'"
                        border
                        :items="locationTypes"
                        :label="$t('attributes.location_type')"
                        slug="location_type"
                    />
                    <DropdownMultiFilter border :items="branches" :label="$t('attributes.branch')" slug="branch" />

                    <ResetFilterButton :href="route('shifts.member.quality-control', { workType: route().params.workType })" :label="$t('buttons.clear')" />
                </FilterSection>
            </template>

            <template #column.name="{ item }">
                {{ item.shift_availability.userable.full_name }}
            </template>

            <template #column.status="{ item }">
                {{ dbTranslate(item.status.name) }}
            </template>

            <template #column.date="{ item }">
                <FromToDate :from="item.start ?? item.shift.start" :to="item.end ?? item.shift.end" class="gap-2" />
            </template>

            <template #column.branch="{ item }">
                {{ item.shift.branch?.name }}
            </template>

            <template #column.clientProject="{ item }">
                {{ item.shift.client_project.name }}
            </template>

            <template v-if="route().params.workType !== 'contact-center'" #column.locationType="{ item }">
                {{ item.shift.location_type.name }}
            </template>
        </DataTable>
        <template #detail v-if="selectedShiftMember">
            <ShiftMemberCard
                :shiftMember="selectedShiftMember"
                @close="toggleCard(selectedShiftMember.id)"
                default-tab="issues"
                :key="selectedShiftMember.id"
            />
        </template>
    </TwoPaneLayout>
</template>

<script setup lang="ts">
import { useForm } from '@inertiajs/vue3'
import { computed } from 'vue'

const form = useForm({})

const disabled = computed(() => form.processing)
</script>

<template>
    <slot v-bind="{ form, disabled }" />
</template>

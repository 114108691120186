<script setup lang="ts">
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/20/solid'
import { ref } from 'vue'

defineProps({
    collapsible: {
        type: Boolean,
        default: true,
    },
})

const collapsed = ref(false)
</script>

<template>
    <div
        :class="!collapsed ? 'w-11/12 max-w-[25rem] md:w-[25rem]' : 'min-w-52'"
        class="absolute right-0 bottom-6 z-10 transform overflow-visible rounded-tl-lg rounded-bl-lg bg-neutral-700/90 px-4 py-4 text-left shadow-lg duration-150 md:top-2 md:bottom-auto md:px-6"
    >
        <div class="flex w-full">
            <button
                v-if="collapsible"
                @click="collapsed = !collapsed"
                class="absolute top-4 -left-2 z-10 mx-auto flex h-5 w-5 shrink-0 items-center justify-center rounded-full bg-neutral-700/100"
            >
                <ChevronRightIcon v-show="!collapsed" class="h-4 w-4 text-zinc-50" aria-hidden="true" />
                <ChevronLeftIcon v-show="collapsed" class="h-4 w-4 text-zinc-50" aria-hidden="true" />
            </button>
            <slot :collapsed="collapsed" />
        </div>
    </div>
</template>

<script setup lang="ts">
import DeleteButton from '@app/components/ui/button/DeleteButton.vue'
import SaveButton from '@app/components/ui/button/SaveButton.vue'
import ModalTitle from '@app/components/ui/calendar/modal/ModalTitle.vue'
import FieldError from '@app/components/ui/form/FieldError.vue'
import Tag from '@app/components/ui/tag/Tag.vue'
import { useNavigation } from '@app/composables/layout/useNavigation'
import type { RecruitmentAppointment, RecruitmentAvailability } from '@app/types/recruitment'
import { addOrRemoveValueFromArray } from '@app/utils/array'
import { getAppointmentDateFromDateTime } from '@app/utils/date'
import { getSlotsTakenStyled } from '@app/utils/recruitment/recruitment-availability'
import { FunnelIcon, MapIcon, MapPinIcon, PaperClipIcon, PencilSquareIcon, PlusIcon, UserGroupIcon, XMarkIcon } from '@heroicons/vue/20/solid'
import { Link, useForm } from '@inertiajs/vue3'
import { format, isFuture } from 'date-fns'
import { trans } from 'laravel-vue-i18n'
import { computed, ref } from 'vue'
import { route } from 'ziggy-js'

const props = defineProps<{
    availability: RecruitmentAvailability
}>()

const showForm = ref(false)
const emit = defineEmits(['close'])

const form = useForm(<
    {
        slots: number
        delete_appointments: number[]
    }
>{
    slots: props.availability.slots,
    delete_appointments: [],
})

const { getRedirectToLeadHref } = useNavigation()

const plannedAppointments = computed(() => props.availability.recruitment_appointments.filter((appointment) => appointment.status === 'planned'))
const cancelledOrCompletedAppointments = computed(() => props.availability.recruitment_appointments.filter((appointment) => appointment.status !== 'planned'))
const isEditable = computed(() => isFuture(new Date(props.availability.from)))
const canDeleteAppointment = computed(() => isEditable.value && props.availability.slots_taken === 0)
const appointmentDate = computed(() => {
    let from = new Date(props.availability.from)
    let date = getAppointmentDateFromDateTime(props.availability.from)
    let start = format(from, 'HH:mm')
    let end = format(new Date(props.availability.to), 'HH:mm')

    return date + ', ' + trans('recruitment.availabilities.from') + ' ' + start + ' ' + trans('recruitment.availabilities.to') + ' ' + end
})

function handleSubmit() {
    form.put(route('recruitment.availability.update', props.availability.id), {
        onSuccess: () => emit('close'),
    })
}

const deleteForm = useForm({
    id: props.availability.id,
})

const deleteAvailability = (id: number) => {
    deleteForm.delete(route('recruitment.availability.destroy', id), {
        preserveScroll: true,
        onSuccess: () => {
            emit('close')
        },
    })
}

function getLeadLinkForAppointment(appointment: RecruitmentAppointment) {
    return getRedirectToLeadHref({
        id: appointment.lead_id,
        full_name: appointment.lead_name,
        funnel: {
            slug: appointment.lead_funnel_slug,
        },
        funnelBlock: {
            slug: appointment.lead_funnel_block_slug,
        },
    })
}

function getTagColor(appointment: RecruitmentAppointment) {
    switch (appointment.status) {
        case 'rescheduled':
            return 'orange'
        case 'cancelled':
            return 'red'
        default:
            return 'green'
    }
}
</script>

<template>
    <div class="bg-white p-6 pb-4">
        <div class="flex w-full items-start">
            <div class="flex w-full flex-col gap-4 text-left">
                <ModalTitle :label="availability.funnel_block_name" :date="appointmentDate" />
                <div class="flex flex-col gap-4 border-b border-zinc-200 pb-6">
                    <div class="flex items-center gap-2">
                        <FunnelIcon class="h-5 w-5 text-zinc-300" aria-hidden="true" />
                        <div class="flex flex-col gap-4 text-sm leading-6 font-normal text-zinc-600">
                            {{ availability.funnel_name }}
                        </div>
                    </div>
                    <div class="flex items-center gap-2">
                        <MapPinIcon class="h-5 w-5 text-zinc-300" aria-hidden="true" />
                        <div class="flex flex-col gap-4 text-sm leading-6 font-normal text-zinc-600">
                            {{ availability.branch_name }}
                        </div>
                    </div>
                    <div v-if="availability.client_project_name" class="flex items-center gap-2">
                        <PaperClipIcon class="h-5 w-5 text-zinc-300" aria-hidden="true" />
                        <div class="flex flex-col gap-4 text-sm leading-6 font-normal text-zinc-600">
                            {{ availability.client_project_name }}
                        </div>
                    </div>
                    <div v-if="availability.location_type_name" class="flex items-center gap-2">
                        <MapIcon class="h-5 w-5 text-zinc-300" aria-hidden="true" />
                        <div class="flex flex-col gap-4 text-sm leading-6 font-normal text-zinc-600">
                            {{ availability.location_type_name }}
                        </div>
                    </div>
                </div>
                <div class="flex flex-col gap-2">
                    <div class="flex h-8 items-center gap-2">
                        <UserGroupIcon class="h-5 w-5 text-zinc-900" aria-hidden="true" />
                        <p class="text-sm leading-6 font-medium text-zinc-900">
                            {{ $t('recruitment.availabilities.participants') }} {{ getSlotsTakenStyled(availability) }}
                        </p>
                        <PencilSquareIcon
                            v-if="isEditable && !showForm"
                            @click="showForm = !showForm"
                            class="h-4 w-4 cursor-pointer text-zinc-500"
                            title="Edit appointment capacity"
                        />
                        <!-- Slots input field -->
                        <div v-if="showForm" class="ml-auto">
                            <FieldError :message="deleteForm.errors.id" />
                            <div class="flex items-baseline gap-3">
                                <input
                                    type="text"
                                    name="slots"
                                    id="slots"
                                    v-model="form.slots"
                                    :placeholder="availability.slots.toString()"
                                    class="focus:border-primary-500 focus:ring-primary-500 block w-12 rounded-md border-none text-xs leading-4 font-medium text-zinc-700 shadow-xs focus:ring-2 focus:outline-hidden"
                                />
                                <span class="text-xs leading-5 font-medium text-zinc-700">{{ $t('calendar.slots') }}</span>
                            </div>
                        </div>
                    </div>

                    <FieldError :message="form.errors.slots" class="mt-2" />
                    <div class="space-y-2">
                        <div v-if="plannedAppointments.length === 0" class="text-xs leading-5 font-normal text-zinc-500">
                            {{ $t('recruitment.availabilities.no_participants') }}
                        </div>
                        <div
                            v-for="appointment in plannedAppointments"
                            class="flex h-fit items-center justify-between gap-2 px-2 py-1"
                            :class="!form.delete_appointments.includes(appointment.id) ? 'bg-white' : 'bg-zinc-50'"
                        >
                            <div
                                class="cursor-default text-sm leading-6 font-normal transition-all duration-150"
                                :class="!form.delete_appointments.includes(appointment.id) ? 'text-zinc-600' : 'text-zinc-400'"
                            >
                                <Link :href="getLeadLinkForAppointment(appointment)" prefetch>{{ appointment.lead_name }}</Link>
                            </div>
                            <template v-if="isEditable">
                                <XMarkIcon
                                    v-if="!form.delete_appointments.includes(appointment.id)"
                                    class="h-5 w-5"
                                    :class="'cursor-pointer text-zinc-400 transition-all duration-150 hover:text-zinc-600'"
                                    @click="addOrRemoveValueFromArray(form.delete_appointments, appointment.id)"
                                />
                                <PlusIcon
                                    v-else
                                    class="h-5 w-5"
                                    :class="'cursor-pointer text-zinc-400 transition-all duration-150 hover:text-zinc-600'"
                                    @click="addOrRemoveValueFromArray(form.delete_appointments, appointment.id)"
                                />
                            </template>
                        </div>

                        <div
                            v-for="appointment in cancelledOrCompletedAppointments"
                            class="flex items-center justify-between gap-2 rounded-sm px-2 py-1"
                            :class="{ 'bg-zinc-50': appointment.status !== 'completed' }"
                        >
                            <div class="cursor-default truncate text-sm leading-6 font-normal text-zinc-600 transition-all duration-150">
                                <Link :href="getLeadLinkForAppointment(appointment)" prefetch>{{ appointment.lead_name }}</Link>
                            </div>

                            <Tag :color="getTagColor(appointment)">{{ $t(`recruitment.appointment.status.${appointment.status}`) }}</Tag>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="flex flex-row-reverse gap-4 px-6 py-3">
        <FieldError :message="deleteForm.errors.id" />
    </div>
    <div v-if="isEditable" class="flex flex-row-reverse gap-4 px-6 py-3">
        <DeleteButton @click="deleteAvailability(availability.id)" :disabled="!canDeleteAppointment" />
        <SaveButton @click="handleSubmit" />
    </div>
</template>

<script setup lang="ts">
import type { Authenticatable, Role } from '@app/types/shared'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { useForm } from '@inertiajs/vue3'
import { computed, type PropType } from 'vue'
import { route } from 'ziggy-js'
import Button from '../ui/button/Button.vue'

let emit = defineEmits(['roles:close'])
const props = defineProps({
    user: {
        type: Object as PropType<Authenticatable>,
        required: true,
    },
    show: Boolean,
    roles: {
        type: Array as PropType<Role[]>,
        required: true,
    },
})

const userRoles = computed(() => props.user.roles?.map(({ id }) => id) || [])

const form = useForm({ role_ids: userRoles.value, user_id: props.user.id, user_type: props.user.type })

const submitRoles = () => {
    form.post(route('user.update-roles'), {
        preserveScroll: true,
        onSuccess: () => emit('roles:close'),
    })
}
</script>

<template>
    <TransitionRoot as="template" :show="show">
        <Dialog as="div" class="relative z-10" @close="emit('roles:close')">
            <TransitionChild
                as="template"
                enter="ease-out duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in duration-200"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
                <div class="fixed inset-0 bg-gray-500/75 transition-opacity" />
            </TransitionChild>
            <div class="fixed inset-0 z-10 overflow-y-auto">
                <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <TransitionChild
                        as="template"
                        enter="ease-out duration-300"
                        enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enter-to="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leave-from="opacity-100 translate-y-0 sm:scale-100"
                        leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <DialogPanel
                            class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
                        >
                            <form @submit.prevent="submitRoles()">
                                <div class="bg-white p-6 sm:pb-4">
                                    <div class="mt-3 sm:mt-0 sm:ml-4 sm:text-left">
                                        <DialogTitle as="h4" class="text-xl leading-6 font-semibold"> {{ $t('form.title.edit_role') }} </DialogTitle>
                                        <div class="mt-4">
                                            <div v-for="role in roles" class="space-y-2">
                                                <div>
                                                    <label class="inline-flex items-center">
                                                        <input
                                                            v-model="form.role_ids"
                                                            class="text-secondary form-checkbox rounded-xl bg-gray-200"
                                                            type="checkbox"
                                                            :checked="userRoles.includes(role.id)"
                                                            :value="role.id"
                                                        />
                                                        <span class="ml-2">{{ role.name }}</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex gap-2 bg-gray-50 px-4 py-3 sm:flex-row-reverse sm:px-6">
                                    <Button :disabled="form.processing">{{ $t('buttons.save') }}</Button>
                                    <Button type="button" color="white" @click="emit('roles:close')">{{ $t('buttons.cancel') }}</Button>
                                </div>
                            </form>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup>
import AddNote from '@app/components/notes/AddNote.vue'
import CallLead from '@app/components/recruitment/leads/actions/CallLead.vue'
import EditLead from '@app/components/recruitment/leads/actions/EditLead.vue'
import OpenLead from '@app/components/recruitment/leads/actions/OpenLead.vue'
import RestoreLead from '@app/components/recruitment/leads/actions/RestoreLead.vue'
import SelectLeadOutcome from '@app/components/recruitment/leads/actions/SelectLeadOutcome.vue'
import SendEmail from '@app/components/recruitment/leads/actions/SendEmail.vue'
import LeadCard from '@app/components/recruitment/leads/LeadCard.vue'
import Badge from '@app/components/ui/badge/Badge.vue'
import RoundButton from '@app/components/ui/button/RoundButton.vue'
import ConversationList from '@app/components/whatsapp/ConversationList.vue'
import MediaFile from '@app/components/whatsapp/MediaFile.vue'
import WhatsappMessageSendForm from '@app/components/whatsapp/WhatsappMessageSendForm.vue'
import { useNavigation } from '@app/composables/layout/useNavigation'
import { useColor } from '@app/composables/useColor'
import { useChannel } from '@app/composables/useEcho'
import { useLocale } from '@app/composables/useLocale'
import { getZonedDateTime } from '@app/utils/date'
import { applyFilter } from '@app/utils/filter'
import { reloadPartial } from '@app/utils/inertia'
import { ArchiveBoxIcon } from '@heroicons/vue/20/solid'
import { router } from '@inertiajs/vue3'
import { onMounted, toRefs, watchEffect } from 'vue'
import { route } from 'ziggy-js'

const props = defineProps({
    whatsappMessages: Object,
    selectedLead: Object,
})

const { selectedLead } = toRefs(props)

const { dbTranslate } = useLocale()
const { getFunnelBlockStatusColor } = useColor()
const { redirectToLead } = useNavigation()

// mark incoming messages as read when conversation is selected
watchEffect(async () => {
    const hasUnreadMessages = selectedLead.value?.whatsapp_messages.some((message) => message.status === 'received') || false

    if (hasUnreadMessages) {
        markThreadAsRead(selectedLead.value.id)
    }
})

const { subscribe } = useChannel('whatsapp')

onMounted(() => {
    subscribe('.whatsapp.message.created', async (message) => {
        // if conversation with the new message is open, read it immediately
        if (message.status === 'received' && message.lead_id === selectedLead.value?.id) {
            markThreadAsRead(message.lead_id)
        } else {
            reloadPartial(['whatsappMessages'])
        }
    })
})

async function markThreadAsRead(leadId) {
    router.post(
        route('recruitment.whatsapp.mark-as-read', leadId),
        {},
        {
            preserveScroll: true,
            preserveState: true,
            only: ['whatsappMessages', 'selectedLead'],
        }
    )
}

function archiveConversation(conversationId) {
    router.post(
        route(
            'recruitment.whatsapp.archive-conversation',
            {
                id: conversationId,
            },
            {
                preserveScroll: true,
            }
        )
    )
}

async function selectConversation(conversation) {
    const leadId = selectedLead.value?.id === conversation.communicable_id ? null : conversation.communicable_id

    applyFilter({ selected: leadId }, { only: ['selectedLead'] })
}
</script>

<template>
    <div class="scrollbar-hide flex flex-col items-start gap-4 lg:h-full lg:min-w-[66.25] lg:flex-row">
        <div class="flex max-h-screen w-full flex-1 overflow-y-auto rounded-lg border border-gray-100 md:min-w-[29rem] lg:h-full lg:w-2/3">
            <ConversationList :items="whatsappMessages" :selected-id="selectedLead?.id" @select="selectConversation" />

            <div v-if="selectedLead" aria-labelledby="message-heading" class="flex min-w-0 flex-1 flex-col" :key="selectedLead.id">
                <div class="flex h-full flex-col justify-between">
                    <div class="z-0 bg-white py-4 shadow-sm">
                        <div class="items-centers flex justify-between px-6">
                            <div class="flex flex-col">
                                <h1 id="message-heading" class="text-lg font-medium text-gray-900 hover:cursor-pointer" @click="redirectToLead(selectedLead)">
                                    {{ selectedLead.full_name }}
                                    <Badge class="ml-4 place-self-center" size="lg" :color="getFunnelBlockStatusColor(selectedLead.funnel_block_status.slug)">{{
                                        dbTranslate(selectedLead.funnel_block_status.name)
                                    }}</Badge>
                                </h1>
                                <p class="mt-1 truncate text-sm text-gray-500">
                                    {{ selectedLead.phone_number }}
                                </p>
                            </div>
                            <RoundButton
                                v-if="!selectedLead.whatsapp_conversation_is_archived"
                                class="place-self-center"
                                @click="archiveConversation(selectedLead.whatsapp_conversation_id)"
                                :title="$t('whatsapp.button_archive')"
                            >
                                <ArchiveBoxIcon class="h-4 w-4 text-gray-700" />
                            </RoundButton>
                        </div>
                    </div>
                    <ul
                        role="list"
                        class="scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-400 flex w-full flex-col-reverse overflow-auto px-6 py-4"
                    >
                        <li
                            v-if="selectedLead"
                            v-for="message in selectedLead.whatsapp_messages"
                            :key="message.id"
                            class="my-3 flex w-2/3 flex-col space-y-2 px-4 pt-6 pb-4 shadow-sm"
                            :class="[message.direction === 'outgoing' ? 'self-end bg-gray-50' : 'bg-primary-50 self-start']"
                        >
                            <div class="sm:flex sm:items-baseline sm:justify-end">
                                <p class="mt-1 text-xs whitespace-nowrap text-gray-500 sm:mt-0 sm:ml-3">
                                    <time :datetime="getZonedDateTime(message.created_at)">{{ getZonedDateTime(message.created_at) }}</time>
                                </p>
                            </div>
                            <MediaFile v-if="message.media_url" :url="message.media_url" />
                            <div v-else class="mt-4 space-y-6 text-sm whitespace-pre-wrap text-gray-800" v-html="message.message" />

                            <div v-if="message.direction === 'outgoing'" class="mt-4 self-end">
                                <Badge v-if="message.status === 'sent'" :label="message.status" color="gray" size="sm" class="shadow-xs" />
                                <Badge v-if="message.status === 'queued'" :label="message.status" color="gray" size="sm" class="shadow-xs" />
                                <Badge v-if="message.status === 'undelivered'" :label="message.status" color="gray" size="sm" class="shadow-xs" />
                                <Badge v-if="message.status === 'delivered'" :label="message.status" color="blue" size="sm" class="shadow-xs" />
                                <Badge v-if="message.status === 'read'" :label="message.status" color="green" size="sm" class="shadow-xs" />
                                <Badge v-if="message.status === 'failed'" :label="message.status" color="red" size="sm" class="shadow-xs" />
                            </div>
                        </li>
                    </ul>
                    <WhatsappMessageSendForm
                        v-if="selectedLead"
                        :model="selectedLead"
                        class="border-t border-gray-100 bg-gray-50 px-4 shadow-[0_-1px_2px_-1px_rgba(0,0,0,0.1)]"
                    />
                </div>
            </div>
        </div>
        <Transition name="slide-fade" mode="out-in">
            <LeadCard v-if="selectedLead" :lead="selectedLead" class="md:min-w-[29rem] lg:w-1/3" @reload="reloadPartial(['selectedLead'])">
                <OpenLead :lead="selectedLead" />
                <CallLead :lead="selectedLead" />
                <SendEmail :lead="selectedLead" />
                <AddNote :model="selectedLead" notable-type="recruitment_lead" />
                <EditLead :lead="selectedLead" />
                <RestoreLead :lead="selectedLead" />
                <SelectLeadOutcome :lead="selectedLead" />
            </LeadCard>
        </Transition>
    </div>
</template>

<script setup>
import { applyFilter } from '@app/utils/filter'
import { MagnifyingGlassIcon } from '@heroicons/vue/20/solid'
import debounce from 'lodash/debounce'
import { inject, ref, watch } from 'vue'

let props = defineProps({
    item: String,
    label: {
        type: String,
        default: 'filters.input.search',
    },
    propertyName: {
        type: String,
        default: 'name',
    },
})

const only = inject('datatable:only')

const property = ref(props.item ?? null)

watch(
    property,
    debounce(function (newValue) {
        applyFilter({ [props.propertyName]: newValue || null }, { only: [only] })
    }, 200)
)
</script>

<template>
    <div class="relative mt-1 rounded-md shadow-xs">
        <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <MagnifyingGlassIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
        </div>
        <input
            v-model="property"
            type="text"
            :placeholder="$t(label)"
            class="focus:border-primary-500 focus:ring-primary-500 block w-full rounded-md border-gray-200 pl-10 text-sm"
            id="input_search"
        />
    </div>
</template>

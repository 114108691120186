<script setup>
const props = defineProps({
    label: String,
    dot: {
        type: Boolean,
        default: false,
    },
    remove: {
        type: Boolean,
        default: false,
    },
    round: {
        type: Boolean,
        default: true,
    },
    color: {
        type: String,
        default: 'gray',
    },
    size: {
        type: String,
        default: 'sm', //sm, lg
    },
})

function colorClasses() {
    const colorMappings = {
        gray: 'text-zinc-800 bg-zinc-100',
        stone: 'text-stone-800 bg-stone-100',
        red: 'text-red-800 bg-red-100',
        orange: 'text-primary-600 bg-primary-100',
        yellow: 'text-yellow-800 bg-yellow-100',
        blue: 'text-blue-800 bg-blue-100',
        green: 'text-green-800 bg-green-100',
        indigo: 'text-indigo-800 bg-indigo-100',
        purple: 'text-purple-800 bg-purple-100',
        pink: 'text-pink-800 bg-pink-100',
        lightBlue: 'text-secondary-500 bg-secondary-50',
        fonkyOrange: 'text-primary-800 bg-primary-100',
        fonkyBlue: 'text-secondary-800 bg-secondary-100',
    }

    return colorMappings[props.color]
}

function iconColor() {
    const colors = {
        gray: 'text-zinc-400 hover:bg-zinc-200 hover:text-zinc-500 focus:bg-zinc-500',
        red: 'text-red-400 hover:bg-red-200 hover:text-red-500 focus:bg-red-500',
        orange: 'text-primary-400 hover:bg-primary-200 hover:text-primary-500 focus:bg-primary-500',
        yellow: 'text-yellow-400 hover:bg-yellow-200 hover:text-yellow-500 focus:bg-yellow-500',
        blue: 'text-blue-400 hover:bg-blue-200 hover:text-blue-500 focus:bg-blue-500',
        green: 'text-green-400 hover:bg-green-200 hover:text-green-500 focus:bg-green-500',
        indigo: 'text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500 focus:bg-indigo-500',
        purple: 'text-purple-400 hover:bg-purple-200 hover:text-purple-500 focus:bg-purple-500',
        pink: 'text-pink-400 hover:bg-pink-200 hover:text-pink-500 focus:bg-pink-500',
    }

    return colors[props.color] || colors.gray
}

function sizeClasses() {
    // TODO: Size is slightly different for button with remove button (alignment)
    const sizeMappings = {
        sm: 'text-xs px-2.5 py-0.5',
        lg: 'text-sm px-3 py-0.5',
    }

    return sizeMappings[props.size] || sizeMappings.md
}

function btnClasses() {
    const borderRadiusClasses = props.round ? 'rounded-full' : 'rounded-sm'
    return `${colorClasses()} ${sizeClasses()} ${borderRadiusClasses}`
}
</script>

<template>
    <div class="inline-flex items-center font-medium" :class="btnClasses()">
        <svg v-if="dot" class="mr-1.5 -ml-0.5 h-2 w-2" :class="iconColor()" fill="currentColor" viewBox="0 0 8 8">
            <circle cx="4" cy="4" r="3" />
        </svg>
        <slot>{{ label }}</slot>
        <button v-if="remove" type="button" class="ml-0.5 inline-flex h-4 w-4 shrink-0 items-center justify-center focus:text-white focus:outline-hidden">
            <span class="sr-only">Remove small option</span>
            <svg class="h-2 w-2" :class="iconColor()" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
            </svg>
        </button>
    </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'

const modelValue = defineModel()

const input = ref<HTMLInputElement>()

defineOptions({
    inheritAttrs: false,
})

onMounted(() => {
    if (input.value?.hasAttribute('autofocus')) {
        input.value.focus()
    }
})

defineExpose({ focus: () => input.value?.focus() })
</script>

<template>
    <div class="relative">
        <input
            ref="input"
            class="focus:border-primary-500 focus:ring-primary-500 w-full rounded-md border-zinc-300 text-sm leading-5 font-normal shadow-xs focus:ring-1 focus:outline-hidden"
            :class="{ 'pl-10': !!$slots.icon, 'bg-gray-100': $attrs.disabled }"
            v-model="modelValue"
            v-bind="$attrs"
        />

        <i v-if="$slots.icon" class="absolute top-1/2 left-3 h-5 w-5 -translate-y-1/2 text-gray-400">
            <slot name="icon"></slot>
        </i>
    </div>
</template>

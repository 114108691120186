<script setup lang="ts">
import Button from '@app/components/ui/button/Button.vue'
import ActionMessage from '@app/components/ui/form/ActionMessage.vue'
import Form from '@app/components/ui/form/Form.vue'
import FormActions from '@app/components/ui/form/FormActions.vue'
import FormField from '@app/components/ui/form/FormField.vue'
import FormSection from '@app/components/ui/form/FormSection.vue'
import Input from '@app/components/ui/form/Input.vue'
import type { PageProps } from '@app/types/inertia'
import { useForm, usePage } from '@inertiajs/vue3'
import { computed } from 'vue'
import { route } from 'ziggy-js'

const form = useForm({
    current_password: '',
    password: '',
    password_confirmation: '',
})

function updatePassword() {
    form.put(route('user.password.update'), {
        preserveScroll: true,
        onSuccess: () => form.reset(),
        onError: () => {
            if (form.errors.password) {
                form.reset('password', 'password_confirmation')
            }

            if (form.errors.current_password) {
                form.reset('current_password')
            }
        },
    })
}

const user = computed(() => usePage<PageProps>().props.user)
</script>

<template>
    <div class="flex w-full flex-col sm:w-1/2">
        <h2 class="text-lg leading-7 font-semibold">
            {{ $t('passwords.update.title') }}
        </h2>
        <p class="mt-1 text-sm leading-6 text-zinc-400">{{ $t('passwords.update.description') }}</p>
    </div>
    <Form :form="form" @submit="updatePassword" class="max-w-xl sm:w-1/2">
        <input hidden type="text" name="username" :value="user.email" autocomplete="username" />
        <FormSection>
            <FormField prop="current_password" class="col-span-12" :label="$t('attributes.current_password')">
                <Input id="current_password" type="password" v-model="form.current_password" autocomplete="current-password" />
            </FormField>

            <FormField prop="password" class="col-span-12" :label="$t('attributes.new_password')">
                <Input id="password" type="password" v-model="form.password" autocomplete="new-password" />
            </FormField>

            <FormField prop="password_confirmation" class="col-span-12" :label="$t('attributes.password_confirmation')">
                <Input id="password_confirmation" v-model="form.password_confirmation" type="password" autocomplete="off" />
            </FormField>
        </FormSection>

        <FormActions class="justify-start!">
            <Button :disabled="form.processing">{{ $t('buttons.save') }}</Button>
            <ActionMessage :on="form.recentlySuccessful">{{ $t('buttons.saved') }}</ActionMessage>
        </FormActions>
    </Form>
</template>

<script setup lang="ts">
import Form from '@app/components/ui/form/Form.vue'
import FormField from '@app/components/ui/form/FormField.vue'
import FormSection from '@app/components/ui/form/FormSection.vue'
import FormTextArea from '@app/components/ui/form/FormTextArea.vue'
import Input from '@app/components/ui/form/Input.vue'
import Label from '@app/components/ui/form/Label.vue'
import type { ShiftMember } from '@app/types/shifts'
import { getTimeFromDateTime } from '@app/utils/date'
import { useForm } from '@inertiajs/vue3'
import { computed } from 'vue'

const { model } = defineProps<{ model: ShiftMember }>()

const form = useForm({
    actual_start_time: getTimeFromDateTime(model.start ?? model.shift_start),
    actual_end_time: getTimeFromDateTime(model.end ?? model.shift_end),
    travel_time_minutes: model.travel_time_minutes,
    note: '',
})

const disabled = computed(() => form.processing)
</script>

<template>
    <Form :form>
        <FormSection>
            <div class="col-span-12">
                <Label>{{ $t('shifts.quality_control.adjust_time') }}</Label>

                <div class="mt-1 flex justify-between gap-2">
                    <FormField prop="actual_start_time flex-1">
                        <Input v-model="form.actual_start_time" placeholder="09:00" />
                    </FormField>

                    <FormField prop="actual_end_time flex-1">
                        <Input v-model="form.actual_end_time" placeholder="15:00" />
                    </FormField>
                </div>
            </div>

            <div class="col-span-12">
                <Label>{{ $t('shifts.quality_control.adjust_travel_time') }}</Label>
                <FormField prop="travel_time_minutes" class="mt-1">
                    <Input v-model="form.travel_time_minutes" placeholder="30" />
                </FormField>
            </div>

            <FormField prop="note" class="col-span-12">
                <FormTextArea v-model="form.note" :placeholder="$t('note.placeholder_optional')" />
            </FormField>
        </FormSection>

        <slot v-bind="{ form, disabled }" />
    </Form>
</template>

<script setup lang="ts">
import { Switch } from '@headlessui/vue'

defineProps<{
    modelValue: boolean
}>()

const emit = defineEmits<{
    'update:modelValue': [value: boolean]
}>()
</script>

<template>
    <Switch
        :modelValue="modelValue"
        @update:modelValue="(value) => emit('update:modelValue', value)"
        :class="[
            modelValue ? 'bg-primary-600' : 'bg-gray-200',
            'relative inline-flex h-5 w-10 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-hidden',
        ]"
    >
        <span class="sr-only">Toggle</span>
        <span
            aria-hidden="true"
            :class="[
                modelValue ? 'translate-x-5' : 'translate-x-0',
                'pointer-events-none inline-block size-4 transform rounded-full bg-white shadow-sm ring-0 transition duration-200 ease-in-out',
            ]"
        />
    </Switch>
</template>

<script setup lang="ts">
import Alert from '@app/components/ui/alert/Alert.vue'
import Checkbox from '@app/components/ui/form/Checkbox.vue'
import FieldError from '@app/components/ui/form/FieldError.vue'
import PhotoInput from '@app/components/ui/form/file/PhotoInput.vue'
import Form from '@app/components/ui/form/Form.vue'
import FormField from '@app/components/ui/form/FormField.vue'
import { useForm } from '@inertiajs/vue3'
import { computed, watch } from 'vue'

const form = useForm({
    start_selfie: null,
    no_selfie: false,
})

watch(
    () => form.start_selfie,
    () => {
        form.clearErrors('start_selfie')
    }
)

const disabled = computed(() => form.processing || (!form.start_selfie && !form.no_selfie))
</script>

<template>
    <Form :form>
        <div class="flex flex-col items-center">
            <PhotoInput v-model="form.start_selfie" class="block min-h-50 w-50">
                {{ $t('shifts.start.selfie_label') }}
            </PhotoInput>

            <FieldError class="mt-2" :message="form.errors.start_selfie" />

            <FormField prop="no_selfie" class="mt-4">
                <Checkbox v-model="form.no_selfie" :disabled="!!form.start_selfie">{{ $t('shifts.start.no_selfie') }}</Checkbox>
            </FormField>
        </div>

        <Alert>{{ $t('shifts.start.selfie_hint') }}</Alert>

        <slot v-bind="{ form, disabled }"></slot>
    </Form>
</template>

<script setup lang="ts">
import { useLocale } from '@app/composables/useLocale'
import { Link } from '@inertiajs/vue3'

defineProps({
    name: {
        type: [String, Object],
        required: true,
    },
    count: Number,
    href: {
        type: String,
        required: true,
    },
    active: {
        type: Boolean,
        default: false,
    },
})

const { translate } = useLocale()
</script>

<template>
    <Link
        :key="name"
        :href="href"
        prefetch
        :class="[
            active ? 'border-primary-500 text-primary-600' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
            'flex justify-center border-b-2 p-4 text-center text-sm font-medium whitespace-nowrap',
        ]"
        :aria-current="active ? 'page' : undefined"
    >
        {{ translate(name) }}

        <span v-if="count" class="bg-primary-50 text-primary-600 ml-2 flex items-center justify-between rounded-xl px-3 text-xs font-bold">{{ count }}</span>
    </Link>
</template>

<script setup lang="ts">
import ActivityFeedTemplate from '@app/components/ui/activityFeed/ActivityFeedTemplate.vue'
import ActivityFeedTemplateHeader from '@app/components/ui/activityFeed/ActivityFeedTemplateHeader.vue'
import Button from '@app/components/ui/button/Button.vue'
import DetailItem from '@app/components/ui/details/DetailItem.vue'
import FormActions from '@app/components/ui/form/FormActions.vue'
import type { OngoingShift } from '@app/types/shifts'
import { getTimeFromDateTime } from '@app/utils/date'
import { DocumentIcon } from '@heroicons/vue/20/solid'
import { router, type InertiaForm } from '@inertiajs/vue3'
import { computed } from 'vue'
import { route } from 'ziggy-js'
import ContactCenterStartShiftForm from './ContactCenterStartShiftForm.vue'
import PromotionsStartShiftForm from './PromotionsStartShiftForm.vue'

const { shift } = defineProps<{ shift: OngoingShift }>()

const emit = defineEmits(['close'])

const title = computed(() =>
    [shift.client_project_name, shift.branch_name, shift.work_type_slug !== 'contact-center' ? shift.location_type_name : null]
        .filter((item) => item !== null)
        .join(', ')
)

const startTime = computed(() => getTimeFromDateTime(shift.start))
const endTime = computed(() => getTimeFromDateTime(shift.end))

const formComponent = computed(() => {
    switch (shift.work_type_slug) {
        case 'promotions':
            return PromotionsStartShiftForm
        default:
            return ContactCenterStartShiftForm
    }
})

function startShift(form: InertiaForm<{}>) {
    form.post(route('shifts.member.start', shift.shift_member_id), {
        preserveScroll: true,
        onSuccess() {
            if (shift.start_url) {
                redirectToShiftStartUrl(shift.start_url)
            }

            emit('close')
        },
    })
}

function redirectToShiftStartUrl(url: string) {
    if (url.startsWith('/')) {
        setTimeout(() => router.get(url), 2000)
    } else {
        setTimeout(() => window.open(url, '_blank'), 7500)
    }
}
</script>

<template>
    <div class="p-8">
        <div class="mb-8">
            <h3 class="mb-4 text-lg">{{ title }}</h3>

            <DetailItem label="Shift time">{{ startTime }} - {{ endTime }}</DetailItem>
        </div>

        <component :is="formComponent" v-slot="{ form, disabled }">
            <div v-if="shift.note" class="relative flex items-start space-x-3">
                <ActivityFeedTemplate>
                    <template #icon>
                        <DocumentIcon class="h-5 w-5 text-gray-500" aria-hidden="true" />
                    </template>
                    <template #header>
                        <ActivityFeedTemplateHeader :name="shift.note.user" :header="$t('activities.note_created')" />
                    </template>
                    <template #content>
                        <div class="text-xs break-words whitespace-pre-wrap">{{ shift.note.note }}</div>
                    </template>
                </ActivityFeedTemplate>
            </div>

            <FormActions>
                <Button @click="startShift(form)" :disabled>{{ $t('buttons.start_shift') }}</Button>
            </FormActions>
        </component>
    </div>
</template>

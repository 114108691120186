<script setup lang="ts">
import SaveButton from '@app/components/ui/button/SaveButton.vue'
import FormActions from '@app/components/ui/form/FormActions.vue'
import type { OngoingShift } from '@app/types/shifts'
import { type InertiaForm } from '@inertiajs/vue3'
import { computed } from 'vue'
import { route } from 'ziggy-js'
import ContactCenterEndShiftForm from './ContactCenterEndShiftForm.vue'
import PromotionsEndShiftForm from './PromotionsEndShiftForm.vue'

const { shift } = defineProps<{ shift: OngoingShift }>()

const emit = defineEmits(['close'])

const formComponent = computed(() => {
    switch (shift.work_type_slug) {
        case 'promotions':
            return PromotionsEndShiftForm
        default:
            return ContactCenterEndShiftForm
    }
})

function endShift(form: InertiaForm<{}>) {
    form.post(route('shifts.member.end', shift.shift_member_id), {
        preserveScroll: true,
        onSuccess: () => emit('close'),
    })
}
</script>

<template>
    <div class="max-w-xl p-8">
        <component :is="formComponent" v-slot="{ form, disabled }">
            <FormActions>
                <SaveButton :disabled @click="endShift(form)">{{ $t('buttons.end_shift') }}</SaveButton>
            </FormActions>
        </component>
    </div>
</template>

<script setup lang="ts">
import RoundButton from '@app/components/ui/button/RoundButton.vue'
import { useActiveButton } from '@app/composables/useDetailCard'
import { useUserCan } from '@app/composables/useUserCan'
import type { ShiftMember } from '@app/types/shifts'
import { CheckIcon } from '@heroicons/vue/20/solid'
import { computed } from 'vue'
import ApproveShiftForm from '../approve/ApproveShiftForm.vue'

const { shiftMember } = defineProps<{ shiftMember: ShiftMember }>()

const { toggle } = useActiveButton()
const { can } = useUserCan()

const show = computed(() => can(`shifts.member.${shiftMember.client_project.work_type.slug}.approve`) && shiftMember.can_be_approved)
</script>

<template>
    <RoundButton v-if="show" :label="$t('buttons.approve')" @click="toggle(ApproveShiftForm)">
        <CheckIcon class="size-5" aria-hidden="true" />
    </RoundButton>
</template>

<script setup lang="ts">
import CallAvailabilityButton from '@app/components/calls/CallAvailabilityButton.vue'
import PageHeader from '@app/components/layout/PageHeader.vue'
import OngoingShiftButton from '@app/components/shifts/shifts/buttons/OngoingShiftButton.vue'
import { useUserCan } from '@app/composables/useUserCan'
import type { PageProps } from '@app/types/inertia'
import type { User } from '@app/types/shared'
import { InboxIcon } from '@heroicons/vue/20/solid'
import { Link, usePage } from '@inertiajs/vue3'
import { computed } from 'vue'
import { route } from 'ziggy-js'
import OpenSideBarButton from './OpenSideBarButton.vue'
import ProfileNavigation from './ProfileNavigation.vue'
import SearchBar from './SearchBar.vue'
import ToggleSideBar from './ToggleSideBar.vue'

defineProps<{ user: User }>()

const { can } = useUserCan()

const enableSearchBar = computed(() => usePage<PageProps>().props.enableSearchBar)
const showTasksLink = computed(() => usePage().props.userTasksCount)
</script>

<template>
    <div class="flex flex-none flex-col">
        <header class="w-full">
            <div class="relative flex h-16 justify-between shadow-xs">
                <OpenSideBarButton />
                <div class="flex w-full items-center justify-between gap-6 bg-white px-6 py-3">
                    <ToggleSideBar />
                    <div class="flex flex-1 items-center justify-between gap-4">
                        <PageHeader />
                        <SearchBar v-if="enableSearchBar" />
                    </div>
                    <div class="flex items-center justify-between gap-2">
                        <CallAvailabilityButton v-if="can('call.availability.show')" />

                        <OngoingShiftButton v-if="can('shifts.member.start') && can('shifts.member.end')" />

                        <Link
                            v-if="showTasksLink"
                            :href="route('user.tasks.index', { status: 'todo' })"
                            prefetch
                            :title="$t('tasks.name')"
                            class="hidden p-2 md:block"
                        >
                            <InboxIcon class="text-primary-600 size-6" />
                        </Link>

                        <ProfileNavigation :user="user" />
                    </div>
                </div>
            </div>
        </header>
    </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'

const modelValue = defineModel()
const input = ref<HTMLInputElement>()

defineOptions({
    inheritAttrs: false,
})

onMounted(() => {
    if (input.value?.hasAttribute('autofocus')) {
        input.value.focus()
    }
})

const textarea = ref()
</script>
<template>
    <textarea
        ref="textarea"
        class="focus:border-primary-600 focus:ring-primary-600 block w-full rounded-md border-gray-300 shadow-xs sm:text-sm"
        :class="{ 'bg-gray-100': $attrs.disabled }"
        v-model="modelValue"
        v-bind="$attrs"
    />
</template>

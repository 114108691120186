<script setup lang="ts">
import Button from '@app/components/ui/button/Button.vue'
import type { CallRecipient } from '@app/types/calls'
import { PhoneArrowDownLeftIcon } from '@heroicons/vue/20/solid'

defineProps<{
    recipient: CallRecipient
}>()

const emit = defineEmits(['accept', 'reject'])
</script>

<template>
    <div class="flex w-full flex-wrap items-start">
        <div class="flex w-full items-center gap-2 md:gap-4">
            <div :class="['animate-pulse']" class="mx-auto flex h-10 w-10 shrink-0 flex-wrap items-center justify-center rounded-full bg-green-100">
                <PhoneArrowDownLeftIcon class="h-6 w-6 text-green-600" aria-hidden="true" />
            </div>
            <div class="flex flex-col gap-1">
                <h3 class="text-base leading-6 font-semibold text-white">
                    {{ $t('call.overlay.incoming_call') }}
                </h3>
                <p class="text-sm text-zinc-50">
                    {{ recipient.full_name || recipient.phone_number }}
                </p>
            </div>
            <div class="flex items-center gap-4">
                <Button size="sm" color="red" @click="emit('reject')">{{ $t('call.overlay.decline') }}</Button>
                <Button size="sm" color="green" @click="emit('accept')">{{ $t('call.overlay.accept') }}</Button>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import CancelButton from '@app/components/ui/button/CancelButton.vue'
import SaveButton from '@app/components/ui/button/SaveButton.vue'
import FormActions from '@app/components/ui/form/FormActions.vue'
import type { ShiftMember } from '@app/types/shifts'
import { type InertiaForm } from '@inertiajs/vue3'
import { computed } from 'vue'
import { route } from 'ziggy-js'
import ContactCenterApproveShiftForm from './ContactCenterApproveShiftForm.vue'
import PromotionsApproveShiftForm from './PromotionsApproveShiftForm.vue'

const { model } = defineProps<{ model: ShiftMember }>()

const formComponent = computed(() => {
    switch (model.work_type_slug) {
        case 'promotions':
            return PromotionsApproveShiftForm
        default:
            return ContactCenterApproveShiftForm
    }
})

function submit(form: InertiaForm<{}>) {
    form.post(route('shifts.member.approve', model.id))
}
</script>

<template>
    <component :is="formComponent" :model v-slot="{ form, disabled }">
        <FormActions>
            <CancelButton @click="$emit('content:closed')"></CancelButton>
            <SaveButton @click="submit(form)" :disabled>{{ $t('buttons.approve') }}</SaveButton>
        </FormActions>
    </component>
</template>
